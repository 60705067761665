.container {
  display: flex;
  padding-bottom: 80px;
}

.auth_container {
  border-top: 1px solid #d8dde2;
}

.rightSide {
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
}

.topButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1rem;

  .register {
    font-weight: 500;
    background: #f1f2f4;
    box-shadow: 0px 5px 5px rgba(238, 239, 239, 0.15);
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1rem;
    padding: 14px 18px;

    &:hover {
      background: #e1e4e8;
    }
  }
}

.modalActions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 10;
}

.content {
  width: 50%;
  max-width: 500px;
  margin: 2rem auto;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #29394d;
}

.resetTitle {
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 2rem;
}

.flexLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  padding: 9px 15px;
}

.forgotLabel {
  color: #6d7f92;
  cursor: pointer;
}

.signinLink {
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 2rem;
}

.successMsg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  color: #06d9b1;
  text-align: center;

  i {
    font-size: 80px;
    margin-bottom: 2rem;

    &::before {
      color: #06d9b1;
    }
  }

  span {
    font-size: 32px;
    line-height: 36px;
  }

  &_send {
    margin-top: 1rem;
    font-size: 22px !important;
    line-height: 26px !important;
    cursor: pointer;
  }
}

.codeBox {
  text-align: center;
  margin: 0 auto;

  p {
    margin-bottom: 10px;
  }

  .error {
    text-align: center;
    margin-top: 10px;
  }
}

.link {
  color: #18a0fb;
  cursor: pointer;
}

.emailInputBox {
  position: relative;

  i {
    position: absolute;
    right: 10px;
    bottom: 27px;
    color: #06d9b1;

    &::before {
      color: #06d9b1;
    }
  }
}
.emailInput {
  background: #ffffff;
  border: 1px solid #18a0fb;
}

.pwdInput {
  &.error {
    border: 1px solid #d50000;
  }
}
span.error {
  color: #d50000;
  font-size: 12px;
  display: block;
  text-align: right;
  line-height: 16px;
  margin-top: 4px;
}

.helpText {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #6d7f92;
  margin-bottom: 20px;
}

.contentLg {
  margin: 0 2rem;

  &_grid {
    display: flex;
  }

  &_left {
    width: 50%;
    padding-right: 2rem;
    border-right: 1px solid #d8dde2;
  }

  &_right {
    width: 50%;
    padding-left: 2rem;
  }
}

.acceptActions {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;

  label {
    cursor: pointer;
    // margin: 0 40px 0 auto;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
  }
}

.acceptError {
  font-size: 12px;
  line-height: 14px;
  color: #fe3745;
  text-align: center;
  margin-top: 10px;
}

.passwordBox {
  position: relative;
}

.progress {
  position: absolute;
  bottom: 5px;
  left: 0;
  margin: 0;
  width: 45%;
  height: 3px;
  border-radius: 10px;
  background: #d8dde2;

  &.error {
    color: #fe3745;
  }
}

.agreationBox {
  display: flex;
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  border-radius: 4px;
}

.agreationSelect {
  width: 65%;
}

.agreation {
  width: 35%;
  border-left: 1px solid #b2bcc6;
  box-sizing: border-box;
  padding-left: 10px;
  height: 34px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  input {
    flex: 2;
    border: none;
    background: transparent;
    font-size: 14px;
  }
}

.btnChangePwd {
  position: relative;
  > span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: #18a0fb;
    cursor: pointer;
    z-index: 9;
  }
}

@media screen and (max-width: 39.9375em) {
  .content {
    width: 100%;
  }

  .leftSide {
    padding: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column-reverse;
  }
}

.codeInput {
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none !important;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input {
    font-size: 1.25rem !important;
  }
}

.footer {
  border-top: 1px solid #d8dde2;
  margin-top: 3rem;
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
