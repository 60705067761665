.accordionBox {
  // max-width: 600px;
  margin: 2rem auto;
}
.accordion {
  border: 1px solid #29394d;
  border-radius: 5px;
  margin-bottom: 10px;

  &_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    background-color: #f1f2f4;
    color: #6d7f92;

    &:hover {
      background-color: #e1e4e8;
    }
  }

  &_name {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &_title,
  &_content {
    padding: 1rem;
  }

  &_content {
    position: relative;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
  }

  &_actions {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.accordion_content {
  details {
    position: relative;
    width: auto;
    height: auto;
    overflow: hidden;
    max-height: 2.5em !important;
    transition: all 0.1s ease;
    ::-webkit-details-marker {
      display: none;
    }
    & > summary {
      position: relative;
      margin-top: 0.25em;
      background: #6d7f92;
      color: #eee;
      padding: 0.1em 0.5em 0.2em 2em;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    & > summary:before {
      position: absolute;
      left: 1em;
      font-family: "tamtam";
      font-size: 1.1rem;
      content: "\e91e";
      transform: rotate(-90deg);
      color: #eee;
      font-size: 0.5em;
      margin-right: 0.5em;
    }
    &[open] > summary {
      background: #6d7f92;
      color: #eee;
      &:before {
        transform: rotate(0deg);
        color: #ccc;
      }
    }
  }

  .folder {
    margin: 0 0 0.5em 0.86em;
    padding: 0.5em 0em 0.5em 1.5em;
    border-left: 0.1em #ccc dotted;
    border-bottom: 0.1em #ccc dotted;

    p {
      margin: 0;
    }
  }

  details[open] {
    height: auto;
    max-height: 99em !important;
    transition: all 1.2s ease;
  }
  details:not([open]) .folder {
    height: 0px !important;
  }
}

.mandatory {
  color: #f4515d;
  background-color: #fff5f6;
}

.btn_action {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: inherit;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.tree_option {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #f1f2f4;
  }
}

.add_app_trigger {
  border: 1px solid #29394d;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f1f2f4;
  color: #6d7f92;

  &:hover {
    background-color: #e1e4e8;
  }
}
