.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(41, 57, 77, 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;

  &.hide {
    display: none;
  }
}

.sidebar {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 30%;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: 0.5s all ease-in-out;
  padding: 2rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #29394d;
  margin-bottom: 2rem;
}

.close {
  text-align: right;
  cursor: pointer;

  i {
    color: #29394d;
  }
}

.content {
  padding: 20px 0;
}

.formRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  > div {
    width: 50%;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.slider {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 2rem;
}

@media screen and (max-width: 39.9375em) {
  .sidebar {
    left: 0%;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sidebar {
    left: 0%;
  }
}
