.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
.wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 5px 5px;
  padding: 1rem;
  overflow: scroll;

  &.loading {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}

.close {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 999999;
  font-size: 2rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.titles {
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #29394d;
    margin-bottom: 10px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    margin-bottom: 1rem;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
