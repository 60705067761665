.ratesPage {
  color: #29394d;
  padding: 2rem 10%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  h1 {
    font-weight: 500rem;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }
  span {
    font-size: 0.875rem;
    color: #6d7f92;
  }
}

.bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #29394d;
  font-weight: 500;
  font-size: 16px;
  z-index: 2;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #fff;
    box-shadow: 0px 4px 5px rgba(60, 78, 100, 0.2);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    position: relative;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    font-size: 16px;
    line-height: 19px;

    &.disabled {
      cursor: initial;
      color: #ccc;

      &:after {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        content: "";
        border-radius: 31px 0px 0px 31px;
        background: rgba(41, 57, 77, 0.1);
      }
    }

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 0.1rem 0 0.3rem;
    }

    span,
    div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    &:not(.disabled):hover,
    &.active {
      transform: translate(-110px);
      background: #2495e1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;
      width: 220px;

      span,
      div {
        opacity: 1;
      }
    }
  }
}
