.pack_detail {
  display: flex;
  align-items: center;
  gap: 20px;
}
.pack_info {
  border-radius: 8px;
  min-height: 186px;
  background-color: #29394d;
  //   background-image: url("/img/effect_pack.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex: 1;
  padding: 14px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .slider {
    width: 160px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &_top {
      display: flex;
      justify-content: space-between;
    }

    &_value {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: #18a0fb;
    }

    &_bottom {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
    }

    &_box {
      height: 40px;
      border-radius: 5px;
      border: 1px solid #e1e4e8;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: block;
      }
    }
  }

  &_name {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #c7e8fe;
    margin-bottom: 18px;
  }
}
.pack_std {
  width: 181px;
  height: 186px;
  padding: 14px;
  background-color: #edfaf5;
  border: 1px solid #02af8e;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pack_pro {
  width: 181px;
  height: 186px;
  padding: 14px;
  border: 1px solid #18a0fb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pack_empty_detail {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 1px;
  &_1 {
    flex: 1;
    height: 50px;
  }
  &_2 {
    width: 160px;
    height: 50px;
    border-left: 1px solid #18a0fb;
    border-right: 1px solid #18a0fb;
    border-top: 1px solid #18a0fb;
    border-radius: 8px 8px 0 0;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
  }
}
.pack_app {
  display: flex;
  align-items: center;
  gap: 20px;
  border-left: 1px solid #29394d;
  padding-left: 20px;

  &_name {
    font-size: 16px;
    color: #18a0fb;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
  // &_category {
  //   margin-bottom: 2rem;
  // }
  &_cat_left {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &_subcat_left {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 1rem;
    padding-bottom: 10px;
    padding-top: 20px;
    border-bottom: 1px solid #e1e4e8;
  }
  &_fonct {
    // padding-left: 2rem;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
  }
  &_left {
    flex: 1;
  }
  //   &_std {
  //     width: 181px;
  //     height: 55px;
  //     border-left: 1px solid #02af8e;
  //     border-right: 1px solid #02af8e;
  //     background-color: #f8f9fa;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
  &_pro {
    width: 160px;
    height: 50px;
    border-left: 1px solid #18a0fb;
    border-right: 1px solid #18a0fb;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_app {
    cursor: pointer;
    width: 160px;
    height: 50px;
    border-left: 1px solid #18a0fb;
    border-right: 1px solid #18a0fb;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pack_app_hover {
  &:hover {
    background-color: #f1f2f4;

    .pack_app_app,
    .pack_app_pro {
      background-color: #f1f2f4;
    }
  }
}
.pack_app_end {
  //   .pack_app_std {
  //     border-bottom: 1px solid #02af8e;
  //     border-radius: 0 0 8px 8px;
  //   }
  .pack_app_pro {
    border-bottom: 1px solid #18a0fb;
    border-radius: 0 0 8px 8px;
  }
}
.pack_sidebar {
  min-height: 600px;
  padding: 20px 20px 120px;
  border-radius: 8px;
  border: 1px solid #e1e4e8;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.1);
  position: relative;

  &_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
  }

  &_noformule {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin: 2rem 0 1rem;
  }

  &_txt {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #6d7f92;
    text-align: center;
  }

  &_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid #e1e4e8;
    background-color: #f3faff;
  }

  &_total {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right;
    margin-top: -5px;
    margin-bottom: 1rem;

    span {
      font-size: 24px;
    }

    small {
      font-size: 14px;
    }
  }
}

.mypack {
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #29394d;
  margin-bottom: 10px;

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e4e8;
  }

  &_name {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &_delete {
    cursor: pointer;
    color: #6d7f92;
  }

  &_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_right {
    text-align: right;
  }
}

.btn_action {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 0;
  cursor: pointer;
  background: #18a0fb;
  color: #fff;
  border-radius: 5px;

  &:hover {
    background-color: #3b8ad5;
  }
}

.btn_second_action {
  color: #6d7f92;
  background-color: #f1f2f4;

  &:hover {
    background-color: #e1e4e8;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: -32px;
  background-color: #fff;
  padding-top: 20px;
}

@media screen and (max-width: 39.9375em) {
  .banner_content {
    padding: 0 10px;
  }
}
