.group {
  min-height: 90px;
}
.label {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 9px 15px;
}
.input {
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 9px 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;

  &:focus {
    border-color: #18a0fb;
  }
  &.error {
    border: 1px solid #d50000;
  }
}
span.error {
  color: #d50000 !important;
  font-size: 12px;
  display: block;
  text-align: right;
  line-height: 16px;
  margin-top: 4px;
}
.star {
  color: #18a0fb;
  margin-left: 4px;
}
.info {
  cursor: pointer;
  margin-left: 10px;
  display: inline;
  position: relative;
  &:hover ul {
    display: block;
  }
  span {
    white-space: nowrap;
  }
  i {
    font-size: 14px;
  }

  ul {
    list-style-type: disc;
    font-size: 16px;
    line-height: 24px;
    list-style-type: disc;
    position: absolute;
    top: 50%;
    right: -380px;
    width: 360px;
    transform: translateY(-50%);
    padding: 20px 30px;
    background: rgba(239, 131, 24, 0.8);
    text-align: left;
    display: none;
    z-index: 999;
    border-radius: 2px;
    color: #fff;
  }
}
