$transitions: ();
@for $i from 1 through 20 {
  $transitions: append($transitions, #{$i * 0.1}s all ease-in-out);
}

.header {
  box-sizing: border-box;
  color: #29394d;
  background-color: #fff;
  font-weight: 500;
  height: 64px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  //   padding: 8px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    color: #29394d;
    position: relative;

    &.active {
      color: #18a0fb;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: #18a0fb;
        position: absolute;
        bottom: -8px;
      }
    }
  }
}

.headerLeft {
  display: flex;
  position: relative;
  z-index: 105;
}

.headerRight {
  display: flex;
  margin-left: auto;
  background-color: #fff;
}

.appLogo {
  height: 3rem;
  margin: 0 0.75rem;
}

.menuLogo {
  padding: 0;
  position: relative;
  align-items: center;
  display: flex;
  z-index: 2;
  cursor: pointer;
  width: 15rem;
  min-height: 64px;
  user-select: none;

  .settingsIcon {
    display: block;
    padding: 20px 16px 16px 14px;
    font-size: 1.25rem;
    transform: rotate(90deg);
    cursor: pointer;
  }

  img {
    height: 38px;
    margin: 0 10px;
  }

  .name {
    // color: $pickled-bluewood;
    font-size: 18px;
    text-transform: initial;
  }

  &.shadow {
    transition: 0.2s all ease-in-out;
  }

  .menuDropdown {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    // color: $pickled-bluewood;
    padding-left: 0;
    padding-right: 0;
    left: 0;
    width: 100%;

    li {
      position: relative;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 15px 0;
        line-height: 1.6;
      }

      &::after {
        content: "";
        display: block;
        left: 0.75rem;
        right: 0.75rem;
        top: 0;
        height: 1px;
        position: absolute;
        background: #f8f9fa;
      }

      &:hover {
        background: #f8f9fa;
        a {
          color: #18a0fb;
        }
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.links {
  display: flex;
  align-items: center;
  gap: 25px;

  .icon {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .iconLoading {
    cursor: initial;
    color: #e8eaed;
  }
}

.signinLinks {
  margin-right: 1.5rem !important;
}

.menuDropdown {
  position: absolute;
  right: 0;
  top: 85%;
  box-shadow: 0 4px 4px rgba(41, 57, 77, 0.1);
  border-radius: 0 0 4px 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0 1rem 1rem;
  background: #fff;
  z-index: 1005;
  width: 24rem;
  background-color: #f1f2f4;
}

.menu a {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}

.menu {
  margin: 0 1.3rem !important;
  align-items: center;

  .menuImg {
    padding: 0.5rem;
  }
  & > li {
    padding: 0.8rem 1rem;
    border-radius: 2rem 2rem 0 0;
    transition: nth($transitions, 2);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2.8rem;
    height: 2.8rem;

    a img {
      margin-right: 0;
    }

    .icon {
      margin-right: 0.4rem;
    }
    & > span {
      font-size: 1.3rem;
      &.dotted:before {
        top: 21%;
        right: 27%;
      }
    }

    &:hover {
      background-color: #f1f2f4;
      img {
        opacity: 0.85;
      }

      .badge {
        visibility: hidden;
      }

      // &.expandable:after {
      //   content: " ";
      //   position: absolute;
      //   bottom: 0px;
      //   z-index: 6;

      //   bottom: -0.6rem;
      //   left: calc(50% - 7px);
      //   z-index: 6;
      //   background: transparent;
      //   border-right: 7px solid transparent;
      //   border-left: 7px solid transparent;
      //   border-bottom: 7px solid #f1f2f4;
      // }
      .menuDropdown,
      .socialLinksWrapper {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.profileContainer {
  margin: 1rem 0;
}

.logout {
  color: #18a0fb;
  cursor: pointer;
  padding: 0.25rem;
  text-align: center;
  margin-top: 1rem;
}

.menuLanguage {
  margin: 0;
  padding: 15px;
  background: #fff;
  margin-bottom: 0 !important;
  cursor: default;

  ul {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-weight: bold;
    height: 2rem;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0 5px;
      border: 1px solid #c7e8fe;
      color: #18a0fb;
      font-size: 14px;
      font-weight: normal;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s font-size ease-in-out;

      &:hover,
      &.selected {
        background-color: #18a0fb;
        color: #fff;
      }
    }
  }
}

.backLink {
  border: 1px solid #18a0fb;
  background-color: #f4faff;
  border-radius: 50px;
  padding: 8px 14px;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 10px;

  i {
    font-size: 12px;
  }
}
.menuProfile {
  margin: 10px 0 10px 0;
  background: #fff;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;

  a {
    color: #29394d;
    white-space: normal;
    padding: 1.5rem 1rem;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    &:hover {
      background: #F8F9FA;
      color: #18a0fb;
    }
  }
}
