.positioning {
  position: fixed;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.popOver {
  width: 500px;
  min-height: 100px;
  max-height: 90vh;
  overflow: scroll;
  // transform: translateY(-50%);
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #18a0fb;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 121;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    padding: 0 1.25rem;
    border: 1px solid #18a0fb;
    border-radius: 4px;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background-color: #18a0fb;
    width: 100%;
  }
}

.close {
  text-align: right;
  cursor: pointer;

  i {
    color: #29394d;
  }
}

.actions {
  margin-top: 20px;
}

.formRow {
  display: flex;
  justify-content: space-between;

  > div {
    width: 49%;
  }
}

.select_app_option {
  display: flex;
  align-items: center;
  gap: 10px;
}
