.modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #fff;
  color: #103d47;
  border-radius: 4px;
  // overflow: hidden;

  // height: 100%;
  // border-radius: 5px;
  // overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalHeader {
  position: relative;
  height: 50px;
  color: #e9ffde;
  background-color: #0f3d47;
  font-size: 24px;
  line-height: 33px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 4px 4px 0 0;
}

.modalTop {
  padding: 2rem;
}

.modalClose {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 999999;
  font-size: 2rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.signinLink {
  cursor: pointer;
}
