.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
.wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 5px 5px;
  padding: 1rem;
  overflow: scroll;

  iframe {
    width: 100%;
    height: 90%;
    box-shadow: none;
    border: none;
  }
}

.close {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 999999;
  font-size: 2rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}
.finish_btn {
  position: absolute;
  right: 30px;
  top: 40px;
  z-index: 10;
}

.steps {
  border: 1px solid #29394d;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 50px;
  background-color: #f1f2f4;
  padding: 3px;
  width: fit-content;
  margin: 1rem auto 2rem !important;
}

.step {
  color: #6d7f92;
  border: 1px solid #f1f2f4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 10px;
  min-width: 160px;
  border-radius: 50px;
  font-weight: 500;

  span {
    background-color: #e1e4e8;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  &:hover {
    background-color: #e1e4e8;
    border-color: #e1e4e8;
  }

  &_active,
  &_active:hover {
    color: #c7e8fe;
    background-color: #29394d;
    border-color: #29394d;

    span {
      color: #6d7f92;
    }
  }
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.acceptActions {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;

  label {
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
  }
}

.acceptError {
  font-size: 12px;
  line-height: 14px;
  color: #fe3745;
  margin-top: 10px;
}

.vatbox {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}

.terms_actions {
  display: flex;
  gap: 20px;
}

.modalActions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 10;
}
