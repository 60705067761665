.offersPage {
  background-color: #fff;
  padding: 0 0 10rem;
  height: 100%;
  min-height: 100vh;
}
.tabs {
  border: 1px solid #29394d;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 50px;
  background-color: #f1f2f4;
  padding: 3px;
  max-width: 464px;
  margin: 0 auto 2rem !important;
}

.tabs_membership {
  flex: 1;
  color: #6d7f92;
  border: 1px solid #f1f2f4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 50px;
  font-weight: 500;

  &:hover {
    background-color: #e1e4e8;
    border-color: #e1e4e8;
  }

  &_active,
  &_active:hover {
    color: #c7e8fe;
    background-color: #29394d;
    border-color: #29394d;
  }
}
.leftSide {
  &.pack_formulas_sm {
    .pack_pro,
    .pack_empty_detail_2,
    .pack_app_pro {
      width: 160px !important;
    }

    .pack_formulas {
      &_box {
        gap: 10px;
      }
    }
    .pack_empty_detail,
    .pack_app {
      gap: 10px;
    }
    .pack_app_cat_left {
      font-size: 16px !important;
    }
    .pack_app_fonct {
      font-size: 14px;
      line-height: 16px;
    }
    .formula {
      font-size: 16px;
    }
  }
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-top: 20px;
}

.pack_formulas {
  &_box {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.formula {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #18a0fb;
  margin-bottom: 10px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: block;
  }
}
.price {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  align-items: baseline;
  gap: 5px;
  flex-shrink: 0;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}
.price_barred {
  color: #de4848;
  display: block;
  text-decoration: line-through;
  font-weight: 400;
  text-align: right;
}
.pack_formulas_slider {
  width: 300px;
  max-width: 100%;
}
.pack_formulas_slider_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pack_formulas_slider_value {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #18a0fb;
}
.pack_detail {
  display: flex;
  gap: 20px;
}
.pack_info {
  border-radius: 8px;
  min-height: 257px;
  background-color: #fff;
  flex: 1;
  padding: 14px;
  font-size: 14px;
  line-height: 16px;

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .slider {
    width: 160px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &_top {
      display: flex;
      justify-content: space-between;
    }

    &_value {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: #18a0fb;
    }

    &_bottom {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
    }

    &_box {
      height: 40px;
      border-radius: 5px;
      border: 1px solid #e1e4e8;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: block;
      }
    }
  }

  &_name {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 18px;
  }
}
.pack_pro {
  width: 181px;
  height: 256px;
  padding: 14px;
  border: 1px solid #b2bcc6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .price {
    margin-bottom: 1rem;
    justify-content: center;
  }
}
.pack_activated {
  border-color: #02af8e !important;
  background-color: #edfaf5 !important;

  .formula {
    color: #02af8e;
  }
}
.pack_selected {
  border-color: #18a0fb;
  background-color: #f4faff;
}
.pack_empty_detail {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 1px;
  width: 100%;

  &_1 {
    flex: 1;
  }
  &_2 {
    width: 181px;
    height: 40px;
    border-left: 1px solid #e1e4e8;
    border-right: 1px solid #e1e4e8;
    background-color: #f8f9fa;
  }
}
.pack_app {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #e1e4e8;

  &:nth-child(even) {
    .pack_app_pro {
      background-color: #fff;
    }
  }

  &_name {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
  // &_category {
  //   margin-bottom: 2rem;
  // }
  &_cat_left {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &_subcat_left {
    font-size: 16px;
    font-style: normal;
    // font-weight: 500;
    line-height: normal;
    // padding-left: 1rem;
    padding-bottom: 10px;
    padding-top: 20px;
    border-bottom: 0.5px solid #e1e4e8;
  }
  &_fonct {
    // padding-left: 2rem;
    display: flex;
    justify-content: space-between;
  }
  &_left {
    flex: 1;
  }

  &_pro {
    width: 181px;
    height: 55px;
    border-left: 1px solid #e1e4e8;
    border-right: 1px solid #e1e4e8;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pack_super_cat {
  border-bottom: 3px solid #b2bcc6;
}
.pack_app_cat {
  border-bottom: 2px solid #b2bcc6;
}
.pack_app_hover {
  &:hover {
    background-color: #f1f2f4;

    .pack_app_pro {
      background-color: #f1f2f4;
    }
  }
}
.pack_app_end {
  width: 100%;
  border: 0;

  .pack_app_pro {
    border-bottom: 1px solid #e1e4e8;
    border-radius: 0 0 8px 8px;
  }
}

.btn_action {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 0;
  cursor: pointer;
  background: #18a0fb;
  color: #fff;
  border-radius: 5px;

  &:hover {
    background-color: #3b8ad5;
  }
}
.btn_confirm {
  background-color: #06d9b1;

  &:hover {
    background-color: #02af8e;
  }
}

.btn_selected {
  border: 1px solid #18a0fb;
  background-color: transparent;
  color: #29394d;

  &:hover {
    background-color: transparent;
  }
}
.btn_second_action {
  color: #6d7f92;
  background-color: #f1f2f4;

  &:hover {
    background-color: #e1e4e8;
  }
}
.btn_disabled {
  color: #fff;
  cursor: not-allowed;
  background-color: #18a0fb;
  opacity: 0.7;

  &:hover {
    background-color: #18a0fb;
  }
}

.container {
  display: flex;
  gap: 2rem;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 0;
}

.topBar {
  color: #02af8e;
  background-color: #edfaf5;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }

  &.topWarning {
    background-color: #f47f4d;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
  }
}

.backBadge {
  background: #18a0fb;
  border-radius: 50px;
  padding: 8px 14px;
  margin-left: 10px;
  color: #fff !important;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 32px;

  i {
    font-size: 12px;
  }
}

.pack_sidebar {
  min-height: 600px;
  padding: 20px 20px 160px;
  border-radius: 8px;
  border: 1px solid #e1e4e8;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.1);
  position: relative;
  width: 325px;

  &_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
  }

  &_noformule {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin: 2rem 0 1rem;
  }

  &_txt {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #6d7f92;
    text-align: center;
    margin-bottom: 1rem;
  }

  &_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid #e1e4e8;
    background-color: #f3faff;
  }

  &_total {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right;
    margin-top: -5px;
    margin-bottom: 1rem;

    span {
      font-size: 24px;
    }

    small {
      font-size: 14px;
    }
  }

  &_secondtotal {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right;
    margin-top: -5px;
    margin-bottom: 1rem;
    color: #6d7f92;

    span {
      font-size: 20px;
    }

    small {
      font-size: 14px;
    }
  }
}

.mypack {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #18a0fb;
  background-color: #f4faff;
  margin-bottom: 10px;

  &_green {
    border-color: #02af8e;
    background-color: #edfaf5;

    .formula {
      color: #02af8e;
    }

    .pack_formulas_slider_value {
      color: #02af8e;
    }
  }

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e4e8;
  }

  &_name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &_delete {
    cursor: pointer;
    color: #6d7f92;
  }

  &_activated {
    color: #02af8e;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
  }

  &_bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &_right {
    text-align: right;
  }

  .formula {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.activated {
  color: #02af8e;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  justify-content: center;
  height: 42px;
}

.pack_related {
  border: 1px solid #d8dde2;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  gap: 8px;

  &_btn {
    margin-top: 10px;
    color: #18a0fb;
    cursor: pointer;
  }
}

.sidebar_users {
  border: 1px solid #d8dde2;
  border-radius: 5px;
  padding: 10px;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
  }

  li {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e4e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .delete {
    cursor: pointer;
  }

  &_txt {
    font-size: 14px;
    line-height: 16px;
    margin: 1rem 0;
  }
}
